<script setup>
import Checkbox from '@/Components/Checkbox.vue'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import InputError from '@/Components/InputError.vue'
import InputLabel from '@/Components/InputLabel.vue'
import TextInput from '@/Components/TextInput.vue'
import { useForm } from '@inertiajs/vue3'

defineProps({
  canResetPassword: {
    type: Boolean,
  },
  status: {
    type: String,
    default: null,
  },
})

const form = useForm({
  email: '',
  password: '',
  remember: false,
})

const submit = () => {
  form.post(route('login'), {
    onFinish: () => form.reset('password'),
  })
}
</script>

<template>
  <GuestLayout>
    <div class="mx-auto max-w-xl rounded-xl bg-white p-6 shadow-lg shadow-gray-800/5 ring-1 ring-gray-900/5">
      <h2 class="mb-5 text-center text-2xl font-semibold text-gray-900">Log in</h2>

      <form @submit.prevent="submit">
        <div>
          <InputLabel for="email" value="Email" />

          <TextInput
            id="email"
            v-model="form.email"
            type="email"
            class="mt-1 block w-full"
            required
            autofocus
            autocomplete="username"
          />

          <InputError class="mt-2" :message="form.errors.email" />
        </div>

        <div class="mt-4">
          <InputLabel for="password" value="Password" />

          <TextInput
            id="password"
            v-model="form.password"
            type="password"
            class="mt-1 block w-full"
            required
            autocomplete="current-password"
          />

          <InputError class="mt-2" :message="form.errors.password" />
        </div>

        <div class="mt-4 block">
          <label class="group flex cursor-pointer items-center">
            <Checkbox v-model:checked="form.remember" name="remember" class="cursor-pointer" />
            <span class="ml-2 text-sm text-gray-600 group-hover:text-gray-900 group-hover:underline">Remember me</span>
          </label>
        </div>

        <div class="text-center">
          <button
            type="submit"
            :class="{ 'opacity-25': form.processing }"
            :disabled="form.processing"
            class="my-6 inline-flex w-full justify-center rounded-lg bg-slate-900 px-4 py-3 text-sm font-semibold text-white duration-300 hover:bg-slate-700"
          >
            Log in
            <span v-if="form.processing" class="ml-2">
              <svg
                class="h-5 w-5 animate-spin text-gray-200 sm:inline"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
            <span v-else aria-hidden="true" class="ml-2 text-gray-200 sm:inline">→</span>
          </button>
        </div>
      </form>

      <div class="mx-auto">
        <div class="relative mt-6">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-200"></div>
          </div>
          <div class="relative flex justify-center text-sm font-medium leading-6">
            <span class="bg-white px-6 text-gray-900">Or sign in with</span>
          </div>
        </div>
      </div>

      <div class="mt-6 space-y-6">
        <div class="text-center">
          <a
            :href="route('etsy.auth', { 'sso-etsy': true })"
            class="relative inline-flex w-full items-center justify-between gap-x-2 rounded-lg border border-gray-200 bg-white px-4 py-3 text-sm font-medium text-gray-800 shadow-sm ring-orange-200 ring-offset-2 transition-all duration-300 hover:border-orange-300 hover:bg-orange-100 hover:text-current active:ring-2"
          >
            <img src="/images/logo-clouds/etsy-logo.png" alt="Etsy logo" class="absolute w-12 object-contain" />
            <span class="w-full text-sm font-semibold leading-6">Sign in with Etsy</span>
          </a>
          <InputError class="mt-2" :message="status" />
        </div>

        <p class="mt-5 text-center text-sm text-gray-500">
          Forgot your password?
          <a class="text-orange-500 hover:underline" :href="route('password.request')">Click here</a>
        </p>
        <p class="mt-5 text-center text-sm text-gray-500">
          New to Prelist?
          <a class="text-orange-500 hover:underline" :href="route('register')">Create an account.</a>
        </p>
      </div>
    </div>
  </GuestLayout>
</template>
